import React, { ReactChild } from 'react';
import './Tooltip.less';
import classNames from 'classnames';

const Tooltip = ({
    children,
    tooltipText,
    width,
}: {
    children?: ReactChild | Element[] | string;
    tooltipText: string | undefined;
    width?: string;
}) => {
    const tipRef = React.useRef<HTMLDivElement | null>(null);

    function handleMouseEnter() {
        if (tipRef.current) {
            tipRef.current.style.opacity = '1';
            tipRef.current.style.marginLeft = '20px';
        }
    }

    function handleMouseLeave() {
        if (tipRef.current) {
            tipRef.current.style.opacity = '0';
            tipRef.current.style.marginLeft = '10px';
        }
    }

    if (!children) return null;

    return (
        <div
            className="relative flex items-center z-10"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className={classNames(
                    'absolute text-sm whitespace-no-wrap bg-gradient-to-r from-gray-700 via-gray-700 to-gray-500 text-white px-4 py-2 rounded flex items-center transition-all duration-150',
                    width,
                )}
                style={{ left: '100%', opacity: 0 }}
                ref={tipRef}
            >
                <div
                    className="bg-gray-700 h-3 w-3 absolute"
                    style={{ left: '-6px', transform: 'rotate(45deg)' }}
                />
                {tooltipText}
            </div>
            {children}
        </div>
    );
};

export default Tooltip;
