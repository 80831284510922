import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import './JobDetails.less';
import { useDispatch, useSelector } from 'react-redux';
import { jobsActions, jobsSelectors } from '../../../../redux/jobs';
import Card from '../../../../components/envago/Card/Card';
import { splitJoin } from '../../../../helper/TextHelper';
import { resolveMeterType, resolveUnitByObis, shortenObis } from '../../../../helper/MeterHelper';
import OptionalLabel from '../../../../components/envago/OptionalLabel/OptionalLabel';
import Toolbar from '../../../../components/envago/Toolbar/Toolbar';
import LabeledList from '../../../../components/envago/LabeledList/LabeledList';
import MeterReadingInput from '../../../../components/envago/MeterReadingInput/MeterReadingInput';
import MeterReadingUnit from '../../../../components/envago/MeterReadingUnit/MeterReadingUnit';
import Button from '../../../../components/envago/Button/Button';
import { formatDate, getLatest, isInFuture } from '../../../../helper/DateHelper';
import Icon from '../../../../components/envago/Icon/Icon';
import Information, { InformationDetails, InformationType } from '../../../../components/envago/Information/Information';
import ReadingValue from '../../../../components/envago/ReadingValue/ReadingValue';
import MeterReadingTarif from '../../../../components/envago/MeterReadingTarif/MeterReadingTarif';
import { MeterRateTaskReadingResultType, MeterRateTaskType, MeterTaskResultType, ReadingEventType } from '../../../../redux/jobs/jobs.types';
import ShareJobDialog from '../../../common/ShareJobDialog/ShareJobDialog';
import PopConfirmButton from '../../../../components/envago/PopConfirmButton/PopConfirmButton';
import LoadingOverlay from '../../../../components/envago/LoadingOverlay/LoadingOverlay';
import UploadImage from '../../../../components/envago/UploadImage/UploadImage';
import JobComment from '../JobComment/JobComment';
import BasicScreen from '../../../common/BasicScreen/BasicScreen';
import JobConstraintWarnings from './JobConstraintWarnings';
import { sortMeterRates } from '../../../../helper/Sorting';
import { Trans, useTranslation } from 'react-i18next';
import MeterReadingJobHelp from '../MeterReadingJobHelp/MeterReadingJobHelp';
import SidePopover from '../../../../components/envago/SidePopover/SidePopover';
import { helpSelectors } from '../../../../redux/help';
import queueSelectors from '../../../../redux/offline/queue.selectors';
import DatePicker from '../../../../components/envago/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { sharesSelectors } from '../../../../redux/shares';
import { isBigger, isInRange as isInRangeHelper } from '../../../../helper/ResultHelper';
import MeterReadingPlausibilityIndicator from '../../../../components/envago/MeterReadingPlausibilityIndicator';
import { isObjectEmpty } from '../../../../helper/DataHelper';
import { ApplicationConfig, DefaultJobComment, FeaturesConfigType, MeterReadingConfigType } from '../../../../redux/application/application.types';
import merge from 'deepmerge';
import { applicationSelectors } from '../../../../redux/application';
import CommunicationJobDetailsSection from '../../../common/Communication/CommunicationJobDetailsSection';

interface ResultStateValueType {
    lastReadingEvent?: ReadingEventType;
    lastReadingResult?: MeterRateTaskReadingResultType;
    readingValue?: string;
    preDecimals: number;
    postDecimals: number;
    valid: boolean;
    readingValueValidation: boolean | null;
    minValue: number;
    maxValue: number;
    showInvalidText: boolean;
    hasReadingValueValidationError: boolean;
    hasHistoricReadingValidationError: boolean;
    hasContinuousReadingValidationError: boolean;
    focus: boolean;
    strictValidation: boolean;
    images: string[];
    isEmptyOrIsInRange: boolean;
    isEmpty: boolean;
}

interface ResultStateType {
    valid: boolean;
    partialValid: boolean;
    maskPostDecimals: boolean;
    queryPostDecimals: boolean;
    values: {
        [key: string]: ResultStateValueType;
    };
}

const initialState: ResultStateType = {
    valid: false,
    partialValid: false,
    maskPostDecimals: false,
    queryPostDecimals: true,
    values: {},
};

const resultReducer = (state: ResultStateType, action: any) => {
    switch (action.type) {
        case 'setFocus': {
            const { id, focus } = action;
            return {
                ...state,
                values: {
                    ...state.values,
                    [id]: {
                        ...state.values[id],
                        focus: focus,
                    },
                },
            };
        }
        case 'setConfig': {
            return {
                ...state,
                maskPostDecimals: action.payload.maskPostDecimals,
                queryPostDecimals: action.payload.queryPostDecimals,
            };
        }
        case 'setJobs': {
            let { job } = action;

            return {
                ...state,
                values: job.meter.meterRateTasks.reduce((res: any, mr: MeterRateTaskType) => {
                    res[mr.taskId] = {
                        lastReadingEvent:
                            (mr.readingEvents || []).length === 0
                                ? undefined
                                : mr.readingEvents?.reduce((prev, current) =>
                                      dayjs(prev.readingDate).valueOf() > dayjs(current.readingDate).valueOf() ? prev : current,
                                  ),
                        lastReadingResult:
                            (mr.readingResults || []).length === 0
                                ? undefined
                                : mr.readingResults?.reduce((prev, current) =>
                                      dayjs(prev.readingDate).valueOf() > dayjs(current.readingDate).valueOf() ? prev : current,
                                  ),
                        preDecimals: mr.preDecimals,
                        postDecimals: mr.postDecimals,
                        readingValueValidation: mr.readingValueValidation,
                        maxValue: mr.readingValueValidation?.maximumValue || Number.MAX_SAFE_INTEGER,
                        minValue: mr.readingValueValidation?.minimumValue || 0,
                        strictValidation: mr.readingValueValidation?.strictValidation,
                        valid: false,
                        showInvalidText: false,
                        hasReadingValueValidationError: false,
                        hasHistoricReadingValidationError: false,
                        hasContinuousReadingValidationError: false,
                        focus: false,
                        isEmptyOrIsInRange: true,
                        isEmpty: true,
                        images: [],
                    };
                    return res;
                }, {}),
            };
        }
        case 'setImages': {
            const { id, images } = action;
            return {
                ...state,
                values: {
                    ...state.values,
                    [id]: {
                        ...state.values[id],
                        images: images,
                    },
                },
            };
        }
        case 'setResult': {
            const { id, value } = action;
            const valueNumber = Number.parseFloat(value);
            const enteredPostDecimals = value.split('.')[1]?.length || 0;
            const strictValidation = state.values[id].strictValidation;
            const isInRange =
                isInRangeHelper(valueNumber, state.values[id].minValue, state.values[id].maxValue, state.values[id].postDecimals) ||
                state.values[id].readingValueValidation === null;
            const isBiggerThanLastReadingEventReadingValue = isBigger(
                valueNumber,
                state.values[id].lastReadingEvent?.readingValue!,
                state.values[id].postDecimals,
            );
            const isBiggerThanLastReadingResultReadingValue = isBigger(
                valueNumber,
                state.values[id].lastReadingResult?.readingValue!,
                state.values[id].postDecimals,
            );

            const ignorePostDecimals = state.maskPostDecimals || !state.queryPostDecimals;

            const postDecimalsMatch = ignorePostDecimals || enteredPostDecimals === state.values[id].postDecimals || state.values[id].postDecimals === null;

            const isValid = isInRange && postDecimalsMatch;
            const isEmptyOrIsInRange = isValid || value.length === 0;

            return {
                ...state,
                values: {
                    ...state.values,
                    [id]: {
                        ...state.values[id],
                        readingValue: value,
                        valid: strictValidation ? isValid : postDecimalsMatch,
                        showInvalidText: !postDecimalsMatch,
                        hasReadingValueValidationError: !isInRange,
                        hasHistoricReadingValidationError: !isBiggerThanLastReadingEventReadingValue,
                        hasContinuousReadingValidationError: !isBiggerThanLastReadingResultReadingValue,
                        isEmptyOrIsInRange: isEmptyOrIsInRange,
                        isEmpty: value.length === 0,
                    },
                },
            };
        }
        case 'deleteResult': {
            const { id } = action;
            return {
                ...state,
                values: {
                    ...state.values,
                    [id]: {
                        ...state.values[id],
                        readingValue: undefined,
                        showInvalidText: false,
                        hasReadingValueValidationError: false,
                        hasHistoricReadingValidationError: false,
                        hasContinuousReadingValidationError: false,
                        valid: false,
                        isEmptyOrIsInRange: true,
                    },
                },
            };
        }
        case 'reset': {
            return initialState;
        }
    }
    return state;
};

const JobDetails = ({ jobId }: { jobId: string; className?: string }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const inputTimer = useRef<null | NodeJS.Timeout>(null);
    const [showDelayedFeedback, setShowDelayedFeedback] = useState(false);

    const job = useSelector(jobsSelectors.getJobById(jobId));

    const isSending = useSelector(jobsSelectors.isSending);
    const showShareButton = useSelector(sharesSelectors.showShareButtonForJob(jobId));

    const applicationConfig: ApplicationConfig = useSelector(applicationSelectors.getApplicationConfig);

    const meterReadingTarifWrapperWidth = useMemo(() => {
        // To set the min-width of the wrapper for the MeterReadingTarif div, estimate the maximum number of pixels needed for the longest word. The factor 13 is used because it has been tested as an ideal approximation (tested with expected words with one leading capital letter) for the length of one letter (NOTE: Uppercase approx. = 17, Lowercase approx. = 13) and to obtain the complete layout without overflow of the unit of measurement (kWh) on the right end. The additional 40 has to be added to compensate the margin of 1.5rem/40px from the children div.
        const meterRateTasksRateNameWordLengths = job.meter.meterRateTasks.map((mr) => {
            if (mr.rateName) {
                return mr.rateName.length;
            } else {
                return 0;
            }
        });
        return Math.min(280, Math.max(...meterRateTasksRateNameWordLengths) * 13 + 40);
    }, [job.meter.meterRateTasks]);

    const jobConfig = useMemo(() => {
        return mergeJobConfig(job.config, applicationConfig);
    }, [job, applicationConfig]);

    const meterReading = useMemo(() => {
        const fallbackReadingDateMaxDaysInPast = job.jobConstraints?.earliestExecution
            ? getLatest(dayjs(job.jobConstraints?.earliestExecution), dayjs().subtract(30, 'days'))
            : dayjs().subtract(30, 'days').startOf('day');

        return {
            ...jobConfig?.meterReading,
            validationFeedbackDelay: jobConfig?.meterReading?.validationFeedbackDelay || 1500,
            maskPostDecimals: jobConfig?.meterReading?.maskPostDecimals ?? false,
            queryPostDecimals: jobConfig?.meterReading?.queryPostDecimals ?? false,
            freeTextCommentEnabled: !jobConfig?.meterReading?.commentsEnabled ? false : jobConfig?.meterReading?.freeTextCommentEnabled ?? true,
            commentsEnabled: jobConfig?.meterReading?.commentsEnabled ?? false,
            readingDate: {
                readingDateMaxDaysInPast:
                    jobConfig?.meterReading.readingDate?.readingDateMaxDaysInPast ?? dayjs().diff(fallbackReadingDateMaxDaysInPast, 'day'),
                readingDateMaxDaysInFuture: jobConfig?.meterReading.readingDate?.readingDateMaxDaysInFuture ?? 0,
            },
        };
    }, [jobConfig, job]);

    const features = useMemo(() => {
        return {
            ...jobConfig?.features,
            imagesForbidden: jobConfig?.features?.imagesForbidden ?? false,
            imageAlwaysRequired: jobConfig?.features?.imagesForbidden ? false : jobConfig?.features?.imageAlwaysRequired ?? false,
            hideReadingDateInput: jobConfig?.features?.hideReadingDateInput ?? false,
            unplausibleReadingsCommentRequired: !meterReading.commentsEnabled ? false : jobConfig?.features?.unplausibleReadingsCommentRequired ?? false,
            unplausibleReadingsImageRequired: jobConfig?.features?.imagesForbidden ? false : jobConfig?.features?.unplausibleReadingsImageRequired ?? false,
            historicReadingsValidationRequired: jobConfig?.features?.historicReadingsValidationRequired ?? false,
            continuousReadingsValidationRequired: jobConfig?.features?.continuousReadingsValidationRequired ?? false,
            durationSendingReadingValuesBasedOnLatestExecutionIsAllowed:
                jobConfig?.features?.durationSendingReadingValuesBasedOnLatestExecutionIsAllowed ?? null,
        };
    }, [jobConfig, meterReading]);

    const meterType = useMemo(() => {
        return resolveMeterType(job.meter);
    }, [job.meter]);

    const sampleObis = useMemo(() => {
        return job.meter.meterRateTasks.find((mrt) => mrt.obis)?.obis;
    }, [job]);

    const [send, setSent] = useState(1);

    const [isPartialValid, setPartialValid] = useState(false);
    const [isValid, setValid] = useState(false);
    const [isCommentSetAndValid, setIsCommentSetAndValid] = useState(false);
    const [strictValidation, setStrictValidation] = useState(false);
    const [hasWarnings, setHasWarnings] = useState(false);
    const [shareDialogVisible, setShareDialogVisible] = useState(false);
    const [readingDate, setReadingDate] = useState(new Date());
    const [comment, setComment] = useState<string | undefined>(undefined);
    const [showReadingHintRequiredWarning, setShowReadingHintRequiredWarning] = useState(false);

    const [resultState, dispatchResult] = useReducer(resultReducer, initialState);

    const isJobPending = useSelector(queueSelectors.isJobPending(job.id));

    const isInputVisible = useMemo(() => {
        if (features?.durationSendingReadingValuesBasedOnLatestExecutionIsAllowed === null) return true;

        if (job.jobConstraints?.latestExecution) {
            const latestExecution = dayjs(job.jobConstraints.latestExecution).add(features?.durationSendingReadingValuesBasedOnLatestExecutionIsAllowed, 'day');

            return dayjs().isBefore(latestExecution);
        }

        return true;
    }, [features?.durationSendingReadingValuesBasedOnLatestExecutionIsAllowed, job]);

    const hasAlwaysImageRequiredErrors = useMemo((): boolean => {
        if (!features?.imageAlwaysRequired) return false;

        return Object.values(resultState.values).reduce((res: boolean, v: any) => res || (!v.isEmpty && v.images.length === 0), false);
    }, [resultState, features?.imageAlwaysRequired]);

    const hasUnplausibleReadingsImageRequiredErrors = useMemo((): boolean => {
        if (!features?.unplausibleReadingsImageRequired) return false;

        return Object.values(resultState.values).reduce((res: boolean, v: any) => {
            if (res) return res;

            if (v.images.length === 0) {
                return (
                    !v.isEmptyOrIsInRange ||
                    (features?.historicReadingsValidationRequired && v.lastReadingEvent != null && v.hasHistoricReadingValidationError) ||
                    (features?.continuousReadingsValidationRequired && v.lastReadingResult != null && v.hasContinuousReadingValidationError)
                );
            } else {
                return false;
            }
        }, false);
    }, [resultState, features?.unplausibleReadingsImageRequired, features?.historicReadingsValidationRequired, features?.continuousReadingsValidationRequired]);

    const hasUnplausibleReadingsCommentRequiredErrors = useMemo((): boolean => {
        if (!features?.unplausibleReadingsCommentRequired) return false;

        return (
            !comment &&
            !isCommentSetAndValid &&
            Object.values(resultState.values).reduce(
                (res: boolean, v: any) =>
                    res ||
                    !v.isEmptyOrIsInRange ||
                    (features?.historicReadingsValidationRequired && v.lastReadingEvent != null && v.hasHistoricReadingValidationError) ||
                    (features?.continuousReadingsValidationRequired && v.lastReadingResult != null && v.hasContinuousReadingValidationError),
                false,
            )
        );
    }, [
        resultState,
        features?.unplausibleReadingsCommentRequired,
        comment,
        isCommentSetAndValid,
        features?.historicReadingsValidationRequired,
        features?.continuousReadingsValidationRequired,
    ]);

    const hasUnplausibleReadingsImageOrCommentRequiredErrors = useMemo(() => {
        if (!hasUnplausibleReadingsImageRequiredErrors && !hasUnplausibleReadingsCommentRequiredErrors) {
            return false;
        }

        if (features?.unplausibleReadingsImageRequired && features?.unplausibleReadingsCommentRequired) {
            if (hasUnplausibleReadingsImageRequiredErrors && hasUnplausibleReadingsCommentRequiredErrors) {
                return true;
            } else if (
                (!hasUnplausibleReadingsImageRequiredErrors && hasUnplausibleReadingsCommentRequiredErrors) ||
                (hasUnplausibleReadingsImageRequiredErrors && !hasUnplausibleReadingsCommentRequiredErrors)
            ) {
                return false;
            }
        }

        return hasUnplausibleReadingsImageRequiredErrors || hasUnplausibleReadingsCommentRequiredErrors;
    }, [
        hasUnplausibleReadingsImageRequiredErrors,
        hasUnplausibleReadingsCommentRequiredErrors,
        features?.unplausibleReadingsImageRequired,
        features?.unplausibleReadingsCommentRequired,
    ]);

    const sendButtonDisabled = useMemo(() => {
        if (hasAlwaysImageRequiredErrors || hasUnplausibleReadingsImageOrCommentRequiredErrors || (comment && !isCommentSetAndValid)) {
            return true;
        }

        return strictValidation ? !isValid : !isPartialValid;
    }, [
        strictValidation,
        isValid,
        isPartialValid,
        hasAlwaysImageRequiredErrors,
        hasUnplausibleReadingsImageOrCommentRequiredErrors,
        comment,
        isCommentSetAndValid,
    ]);

    const hasInstructions = useSelector(helpSelectors.hasInstructions(job.meter.meterRateTasks.length, job.meter.meterNumber, sampleObis, job.meter.meterType));

    const doneHint = useMemo(() => {
        let translationKey = 'jobs.details.doneHint';

        const now = dayjs().valueOf();
        const targetReadingDates = Object.values(job.meter.meterRateTasks).map((task) => dayjs(task.targetReadingDate).valueOf());
        const latestExecution = dayjs(job.jobConstraints?.latestExecution).valueOf();

        if (targetReadingDates.some((date) => date < now) && now < latestExecution) {
            translationKey = 'jobs.details.doneHintLate';
        } else if (now > latestExecution) {
            translationKey = 'jobs.details.doneHintOverdue';
        }

        return (
            <Trans i18nKey={translationKey}>
                <b>title</b>
                <br />
                description
            </Trans>
        );
    }, [job]);

    const meterAddressReadable = useMemo(() => {
        if (!job.meter.meterAddress || isObjectEmpty(job.meter.meterAddress)) {
            return null;
        }

        let companyFullName: string | null = null;

        if (job.meter.meterAddress?.company || (job.meter.meterAddress?.firstname && job.meter.meterAddress?.lastname)) {
            const company = job.meter.meterAddress?.company === job.customer?.company ? null : job.meter.meterAddress?.company;

            const fullName =
                job.meter.meterAddress?.firstname === job.customer?.firstname && job.meter.meterAddress?.lastname === job.customer?.lastname
                    ? null
                    : splitJoin(', ', job.meter.meterAddress?.firstname, job.meter.meterAddress?.lastname);

            companyFullName = splitJoin(', ', company, fullName);
        }

        return splitJoin(
            ', ',
            companyFullName,
            splitJoin(' ', job.meter.meterAddress?.street, job.meter.meterAddress?.houseNumber),
            splitJoin(' ', job.meter.meterAddress?.zip, job.meter.meterAddress?.city),
        );
    }, [job]);

    useEffect(() => {
        if (resultState?.values) {
            setValid(Object.values(resultState.values).reduce((res: boolean, v: any) => res && v.valid, true));

            setPartialValid(Object.values(resultState.values).reduce((res: boolean, v: any) => res || v.valid, false));

            setIsCommentSetAndValid(comment !== undefined && comment.replace(/\s/g, '').length >= 5);

            setHasWarnings(Object.values(resultState.values).reduce((res: boolean, v: any) => res || v.warning, false));

            const showMissingHint = Object.values(resultState.values).reduce((res: boolean, v: any) => res || !v.isEmptyOrIsInRange, false);

            const isAnyFocused = Object.values(resultState.values).reduce((res: boolean, v: any) => res || v.focus, false);

            const hasImages = Object.values(resultState.values).reduce((res: boolean, v: any) => res || v.images.length > 0, false);

            if (features?.unplausibleReadingsImageRequired && features?.unplausibleReadingsCommentRequired) {
                setShowReadingHintRequiredWarning(
                    features?.unplausibleReadingsCommentRequired &&
                        showMissingHint &&
                        features?.unplausibleReadingsImageRequired &&
                        !hasImages &&
                        !isAnyFocused &&
                        !comment,
                );
            } else {
                setShowReadingHintRequiredWarning(features?.unplausibleReadingsCommentRequired && showMissingHint && !isAnyFocused && !comment);
            }
        }
    }, [resultState, features?.unplausibleReadingsCommentRequired, features?.unplausibleReadingsImageRequired, comment]);

    useEffect(() => {
        dispatchResult({
            type: 'setConfig',
            payload: {
                maskPostDecimals: meterReading?.maskPostDecimals,
                queryPostDecimals: meterReading?.queryPostDecimals,
            },
        });
    }, [jobId, meterReading?.maskPostDecimals, meterReading?.queryPostDecimals]);

    useEffect(() => {
        if (job) {
            dispatchResult({ type: 'setJobs', job });
        }

        return () => {
            dispatchResult({ type: 'reset' });
            setReadingDate(new Date());
        };
    }, [jobId]);

    useEffect(() => {
        if (job) {
            const strict = job.meter.meterRateTasks.reduce((res, mr) => res || mr.readingValueValidation?.strictValidation || false, false);
            setStrictValidation(strict);
        }
    }, [job]);

    if (!job) return null;

    return (
        <BasicScreen key={job.id}>
            <ShareJobDialog jobs={[job]} visible={shareDialogVisible} onClose={setShareDialogVisible} />
            <Toolbar
                showBackButton={true}
                title={t('jobs.details.title')}
                toolbarButtons={
                    showShareButton && (
                        <Button
                            className={'w-20'}
                            small={true}
                            primary={false}
                            ghost={true}
                            onClick={() => {
                                setShareDialogVisible(true);
                            }}
                        >
                            <Icon path={Icon.Path.mdiShare} /> {t('jobs.details.buttons.share')}
                        </Button>
                    )
                }
            />

            {isInputVisible && <JobConstraintWarnings constraints={job.jobConstraints} />}

            {!isInputVisible && (
                <Information type={InformationType.INFO} className={'mb-2'}>
                    <InformationDetails
                        title={t('jobs.details.warnings.constraints.afterSendingReadingValuesIsAllowedBasedOnLatestExecutionAndDuration.title')}
                    >
                        <Trans i18nKey={'jobs.details.warnings.constraints.afterSendingReadingValuesIsAllowedBasedOnLatestExecutionAndDuration.description'}>
                            Leider kann der Zählerstand dieser Ablesung nicht mehr übermittelt werden, da der Ablesezeitraum überschritten ist.
                            <br /> Bitte wenden Sie sich bei Fragen an den Kundenservice.
                        </Trans>
                    </InformationDetails>
                </Information>
            )}

            <Card>
                <SidePopover
                    hidden={!hasInstructions}
                    title={t('help.sidePopOver.title')}
                    text={
                        <>
                            <Icon path={Icon.Path.mdiHelpCircle} className={'h-6'} />
                            {t('help.sidePopOver.button')}
                        </>
                    }
                >
                    <MeterReadingJobHelp
                        numberOfMeterRates={job.meter.meterRateTasks.length}
                        meterNumber={job.meter.meterNumber}
                        obis={sampleObis}
                        meterType={job.meter.meterType}
                    />
                </SidePopover>
                <LabeledList>
                    <OptionalLabel label={t('jobs.details.labels.meterNumber')}>{job.meter.meterNumber}</OptionalLabel>

                    <OptionalLabel label={t('jobs.details.labels.meterType')}>{job.meter.customMeterType || t(`common.metertypes.${meterType}`)}</OptionalLabel>

                    <OptionalLabel label={t('jobs.details.labels.customerNumber')}>{job.customer?.customerNumber}</OptionalLabel>

                    <OptionalLabel label={t('jobs.details.labels.customer')}>
                        {splitJoin(', ', job.customer?.company, splitJoin(' ', job.customer?.firstname, job.customer?.lastname))}
                    </OptionalLabel>

                    <OptionalLabel label={t('jobs.details.labels.partner')}>
                        {splitJoin(', ', job.partner?.company, splitJoin(' ', job.partner?.firstname, job.partner?.lastname))}
                    </OptionalLabel>

                    <OptionalLabel label={t('jobs.details.labels.deliveryPoint')}>{meterAddressReadable}</OptionalLabel>

                    <OptionalLabel label={t('jobs.details.labels.objectKey')}>{job.meter.meterAddress?.objectKey}</OptionalLabel>

                    <OptionalLabel label={t('jobs.details.labels.locationDescription')}>{job.meter.meterAddress?.locationDescription}</OptionalLabel>

                    <OptionalLabel label={t('jobs.details.labels.hint')}>{job.meter.meterAddress?.hint}</OptionalLabel>
                </LabeledList>
            </Card>

            {isJobPending && !job.done && (
                <Card className={'text-sm'}>
                    <Information type={InformationType.INFO}>
                        <p>
                            <Trans i18nKey={'jobs.details.pendingHint'}>
                                <b>Dieser Zähler wurde bereits abgelesen</b>
                                <br />
                                Das Ergebnis wird gesendet, sobald eine Internetverbindung besteht!
                            </Trans>
                        </p>
                    </Information>
                </Card>
            )}

            {job.done && (
                <Card className={'text-sm'}>
                    <Information type={InformationType.SUCCESS}>
                        <p>{doneHint}</p>
                        <CommunicationJobDetailsSection />
                    </Information>
                    <>
                        {sortMeterRates(job.meter.meterRateTasks).map((mr) => {
                            return (
                                <Card key={mr.taskId}>
                                    <LabeledList>
                                        <OptionalLabel label={t('jobs.details.labels.obis')}>
                                            {mr.rateName
                                                ? splitJoin(' ', mr.rateName, mr.obis && `(${shortenObis(mr.obis)})`)
                                                : mr.obis && `${shortenObis(mr.obis)}`}
                                        </OptionalLabel>
                                        <OptionalLabel label={t('jobs.details.labels.readingDate')}>
                                            {formatDate(mr.readingResults?.slice(-1)[0].readingDate)}
                                        </OptionalLabel>
                                        <OptionalLabel label={t('jobs.details.labels.readingValue')} className={'flex flex-row'}>
                                            <ReadingValue
                                                value={mr.readingResults?.slice(-1)[0].readingValue}
                                                postDecimals={meterReading?.queryPostDecimals ? mr.postDecimals : 0}
                                                preDecimals={mr.preDecimals}
                                                maskPostDecimals={meterReading?.maskPostDecimals}
                                            />
                                            <MeterReadingUnit className={'text-gray-600'} unit={mr.unit} obis={mr.obis} />
                                            <MeterReadingPlausibilityIndicator
                                                readingValue={mr.readingResults?.slice(-1)[0].readingValue}
                                                maximumValue={mr.readingValueValidation?.maximumValue}
                                                minimumValue={mr.readingValueValidation?.minimumValue}
                                            />
                                        </OptionalLabel>
                                    </LabeledList>
                                </Card>
                            );
                        })}
                    </>
                </Card>
            )}

            {isInputVisible && (
                <LoadingOverlay loading={isSending} text={t('jobs.details.sending')}>
                    <Card>
                        <>
                            {job.meter.meterRateTasks.length > 1 && (
                                <Information className={'mb-3 text-sm'}>
                                    <Trans i18nKey={'jobs.details.multiObisWarning'} values={{ meterRateCount: job.meter.meterRateTasks.length }}>
                                        {'Dieser Zähler hat '}
                                        <span className={'font-medium'}>{{ meterRateCount: job.meter.meterRateTasks.length }} Tarife</span>
                                        {': Bitte beachte die Tarif-Bezeichnungen!'}
                                    </Trans>
                                </Information>
                            )}
                        </>
                        <LabeledList>
                            <>
                                {sortMeterRates(job.meter.meterRateTasks).map((mr) => {
                                    const hasAnyValidationError =
                                        !resultState.values[mr.taskId]?.isEmptyOrIsInRange ||
                                        (features?.historicReadingsValidationRequired &&
                                            resultState.values[mr.taskId]?.lastReadingEvent != null &&
                                            resultState.values[mr.taskId]?.hasHistoricReadingValidationError) ||
                                        (features?.continuousReadingsValidationRequired &&
                                            resultState.values[mr.taskId]?.lastReadingResult != null &&
                                            resultState.values[mr.taskId]?.hasContinuousReadingValidationError);

                                    const showImageRequiredError =
                                        features?.unplausibleReadingsImageRequired &&
                                        resultState.values[mr.taskId]?.images.length === 0 &&
                                        hasAnyValidationError;

                                    const showCommentRequiredError =
                                        features?.unplausibleReadingsCommentRequired &&
                                        !(comment && comment.length > 0 && isCommentSetAndValid) &&
                                        hasAnyValidationError;

                                    const showImageOrCommentRequiredError = showImageRequiredError && showCommentRequiredError;

                                    const isFeedInMeter = mr.obis?.includes(':2.8');
                                    const lastHistoricReadingEvent = resultState.values[mr.taskId]?.lastReadingEvent;
                                    const unit = mr.unit || resolveUnitByObis(mr?.obis);
                                    const interpolationParams = {
                                        historicReadingValue: lastHistoricReadingEvent != null ? lastHistoricReadingEvent.readingValue : undefined,
                                        unit: lastHistoricReadingEvent != null ? unit : undefined,
                                        historicReadingDate:
                                            lastHistoricReadingEvent != null ? dayjs(lastHistoricReadingEvent.readingDate).format('DD.MM.YYYY') : undefined,
                                    };

                                    let errorText = undefined;
                                    if (resultState.values[mr.taskId]?.showInvalidText) {
                                        errorText = t('jobs.details.warnings.invalidText', {
                                            count: mr.postDecimals,
                                        });
                                    } else if (showImageRequiredError && !features?.unplausibleReadingsCommentRequired) {
                                        if (isFeedInMeter) {
                                            errorText = t('jobs.details.warnings.imageRequiredFeedInMeterError', interpolationParams);
                                        } else {
                                            errorText = t('jobs.details.warnings.imageRequiredError', interpolationParams);
                                        }
                                    } else if (showImageOrCommentRequiredError) {
                                        if (isFeedInMeter) {
                                            errorText = t('jobs.details.warnings.imageOrCommentRequiredFeedInMeterError', interpolationParams);
                                        } else {
                                            errorText = t('jobs.details.warnings.imageOrCommentRequiredError', interpolationParams);
                                        }
                                    }

                                    const showHistoricReadingsValidationWarning =
                                        features?.historicReadingsValidationRequired &&
                                        resultState.values[mr.taskId]?.lastReadingEvent != null &&
                                        resultState.values[mr.taskId]?.hasHistoricReadingValidationError;

                                    const showContinuousReadingsValidationWarning =
                                        features?.continuousReadingsValidationRequired &&
                                        resultState.values[mr.taskId]?.lastReadingResult != null &&
                                        resultState.values[mr.taskId]?.hasContinuousReadingValidationError;

                                    let warningText = undefined;
                                    if (showContinuousReadingsValidationWarning) {
                                        warningText = t('jobs.details.warnings.continuousReadingsValidationWarningText', interpolationParams);
                                    } else if (showHistoricReadingsValidationWarning) {
                                        if (isFeedInMeter) {
                                            warningText = t('jobs.details.warnings.historicValidationFeedInMeterWarningText', interpolationParams);
                                        } else {
                                            warningText = t('jobs.details.warnings.historicValidationWarningText', interpolationParams);
                                        }
                                    } else if (resultState.values[mr.taskId]?.hasReadingValueValidationError) {
                                        warningText = t('jobs.details.warnings.validationWarningText', interpolationParams);
                                    }

                                    const hasFocus = resultState.values[mr.taskId]?.focus;
                                    const showFeedback: boolean = !hasFocus || showDelayedFeedback;

                                    return (
                                        <React.Fragment key={`${mr.taskId}-${send}`}>
                                            <OptionalLabel label={t('jobs.details.labels.reading')} className={'flex items-center flex-wrap xl:flex-nowrap'}>
                                                <div className={'w-full flex items-center md:w-max md:justify-start justify-center'}>
                                                    <div
                                                        className={'break-all'}
                                                        style={{
                                                            width: meterReadingTarifWrapperWidth,
                                                            minWidth: 100,
                                                        }}
                                                    >
                                                        {/*style={{minWidth: `${meterReadingTarifWrapperWidth}px`}}*/}
                                                        <MeterReadingTarif obis={mr.obis} rateName={mr.rateName} />
                                                    </div>

                                                    <MeterReadingInput
                                                        maskPostDecimals={meterReading?.maskPostDecimals}
                                                        preDecimals={mr.preDecimals || 8}
                                                        postDecimals={meterReading?.queryPostDecimals ? mr.postDecimals || 0 : 0}
                                                        key={`${mr.taskId}-${send}`}
                                                        value={resultState?.values[mr.taskId]?.readingValue}
                                                        onFocusChange={(focus) => {
                                                            if (!focus) {
                                                                setShowDelayedFeedback(false);
                                                            }

                                                            dispatchResult({
                                                                type: 'setFocus',
                                                                focus: focus,
                                                                id: mr.taskId,
                                                            });
                                                        }}
                                                        onChange={(value) => {
                                                            if (value && value.length > 0) {
                                                                if (inputTimer.current) {
                                                                    clearTimeout(inputTimer.current);
                                                                    setShowDelayedFeedback(false);
                                                                }

                                                                inputTimer.current = setTimeout(() => {
                                                                    setShowDelayedFeedback(true);
                                                                }, meterReading?.validationFeedbackDelay);

                                                                dispatchResult({
                                                                    type: 'setResult',
                                                                    id: mr.taskId,
                                                                    value,
                                                                });
                                                            } else {
                                                                dispatchResult({
                                                                    type: 'deleteResult',
                                                                    id: mr.taskId,
                                                                });
                                                            }
                                                        }}
                                                    />

                                                    <MeterReadingUnit className={'text-gray-600'} obis={mr.obis} unit={mr.unit} />

                                                    {showFeedback &&
                                                        resultState.values[mr.taskId]?.valid &&
                                                        !(
                                                            resultState.values[mr.taskId]?.showInvalidText ||
                                                            resultState.values[mr.taskId]?.hasReadingValueValidationError ||
                                                            showHistoricReadingsValidationWarning ||
                                                            showContinuousReadingsValidationWarning
                                                        ) && (
                                                            <div className={'lg:ml-3 relative'}>
                                                                <Icon path={Icon.Path.mdiCheck} className={'h-6 text-green-500 absolute left-1 -top-3'} />
                                                            </div>
                                                        )}
                                                </div>

                                                <div className={'text-sm lg:ml-3 xl:mt-0 mt-2'}>
                                                    {showFeedback && (
                                                        <>
                                                            <Information type={InformationType.ERROR}>{errorText}</Information>
                                                            {!errorText && (
                                                                <Information type={strictValidation ? InformationType.ERROR : InformationType.WARNING}>
                                                                    {warningText}
                                                                </Information>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </OptionalLabel>
                                            {!features?.imagesForbidden && (
                                                <OptionalLabel label={t('jobs.details.labels.readingImage')}>
                                                    <UploadImage
                                                        primary={hasAlwaysImageRequiredErrors || hasUnplausibleReadingsImageRequiredErrors}
                                                        onChange={(images) => {
                                                            dispatchResult({
                                                                type: 'setImages',
                                                                id: mr.taskId,
                                                                images,
                                                            });
                                                        }}
                                                        text={t('jobs.details.buttons.uploadImage')}
                                                    />
                                                </OptionalLabel>
                                            )}

                                            {!job.sameTargetReadingDates && (
                                                <OptionalLabel label={t('jobs.details.labels.targetReadingDate')}>
                                                    {formatDate(mr.targetReadingDate)}
                                                </OptionalLabel>
                                            )}
                                            <hr className={'col-span-2'} />
                                        </React.Fragment>
                                    );
                                })}
                            </>

                            {!features?.hideReadingDateInput && !(job.jobConstraints?.earliestExecution && isInFuture(job.jobConstraints?.earliestExecution)) && (
                                <OptionalLabel label={t('jobs.details.labels.readingDate')}>
                                    <DatePicker
                                        value={readingDate}
                                        minDate={dayjs().subtract(meterReading.readingDate.readingDateMaxDaysInPast, 'days').startOf('day').toDate()}
                                        maxDate={dayjs().add(meterReading.readingDate.readingDateMaxDaysInFuture, 'days').endOf('day').toDate()}
                                        onChange={setReadingDate}
                                    />
                                </OptionalLabel>
                            )}
                            {meterReading.commentsEnabled && (
                                <OptionalLabel label={t('jobs.details.labels.readingHint')}>
                                    <JobComment
                                        primary={
                                            !(hasAlwaysImageRequiredErrors || hasUnplausibleReadingsImageRequiredErrors) &&
                                            hasUnplausibleReadingsImageOrCommentRequiredErrors
                                        }
                                        additionalComments={meterReading?.defaultComments}
                                        key={`comment-${send}`}
                                        onChange={(text) => {
                                            setComment(text);
                                        }}
                                        isFreeTextOptionEnabled={meterReading?.freeTextCommentEnabled}
                                    />
                                </OptionalLabel>
                            )}

                            {showReadingHintRequiredWarning && !features?.unplausibleReadingsImageRequired && !features?.historicReadingsValidationRequired && (
                                <OptionalLabel>
                                    <Information type={InformationType.ERROR}>{t('jobs.details.warnings.hintRequiredError')}</Information>
                                </OptionalLabel>
                            )}

                            {!showReadingHintRequiredWarning && !isCommentSetAndValid && comment && (
                                <OptionalLabel>
                                    <Information type={InformationType.WARNING}>{t('jobs.details.warnings.hintMinLengthWarning')}</Information>
                                </OptionalLabel>
                            )}

                            <OptionalLabel className={'lg:mt-0 mt-2'}>
                                {features.imageAlwaysRequired && (
                                    <p className={'description'}>
                                        {t('jobs.details.warnings.imageAlwaysRequiredHint', {
                                            count: job.meter.meterRateTasks.length,
                                        })}
                                    </p>
                                )}
                                <PopConfirmButton
                                    primary={true}
                                    block={true}
                                    disabled={sendButtonDisabled}
                                    isConfirmed={!hasWarnings && isValid}
                                    onClick={() => {
                                        let results = Object.entries(resultState.values)
                                            .map(([taskId, reading]) => {
                                                let r = reading as ResultStateValueType;
                                                if (r.readingValue && r.valid) {
                                                    return {
                                                        taskId: taskId,
                                                        comment: comment,
                                                        readingValue: Number.parseFloat(r.readingValue),
                                                        readingImages: r.images
                                                            .map((image) => {
                                                                let imageMatch = image.match(/.*data:(.*);.*,(.*)/m);

                                                                if (imageMatch) {
                                                                    return {
                                                                        encodedImage: imageMatch[2],
                                                                        mimeType: imageMatch[1],
                                                                    };
                                                                }
                                                                return {
                                                                    encodedImage: '',
                                                                    mimeType: '',
                                                                };
                                                            })
                                                            .filter((a) => a.encodedImage.length > 0),
                                                    } as MeterTaskResultType;
                                                }
                                                return undefined;
                                            })
                                            .filter((item): item is MeterTaskResultType => !!item);

                                        dispatch(jobsActions.sendMeterTaskResults(jobId, readingDate.toISOString(), results));

                                        setSent(send + 1);
                                    }}
                                    okText={t('jobs.details.send.confirm')}
                                    cancelText={t('jobs.details.send.cancel')}
                                    title={hasWarnings ? t('jobs.details.warnings.check.title') : t('jobs.details.warnings.incomplete.title')}
                                    content={
                                        isValid ? (
                                            <>
                                                <p className={'text-gray-500 font-medium'}>
                                                    {t('jobs.details.warnings.check.message')}
                                                    <br />
                                                    <br />
                                                </p>
                                                <p>{t('jobs.details.warnings.check.details')}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p className={'text-gray-500 font-medium'}>
                                                    {t('jobs.details.warnings.incomplete.message')}
                                                    <br />
                                                    <br />
                                                </p>
                                                <p>{t('jobs.details.warnings.incomplete.details')}</p>
                                            </>
                                        )
                                    }
                                >
                                    {t('jobs.details.buttons.send')}
                                </PopConfirmButton>
                            </OptionalLabel>
                        </LabeledList>
                    </Card>
                </LoadingOverlay>
            )}
        </BasicScreen>
    );
};

const mergeJobConfig = (jobConfig?: ApplicationConfig, applicationConfig?: ApplicationConfig): ApplicationConfig | undefined => {
    if (!jobConfig && !applicationConfig) return undefined;

    const resolveDefaultComments = (comments1: Array<DefaultJobComment>, comments2: Array<DefaultJobComment>) => comments2 || comments1 || [];

    const meterReadingOptions = {
        customMerge: (key: string) => {
            if (key === 'defaultComments') {
                return resolveDefaultComments;
            }
        },
    };

    const c: Partial<ApplicationConfig> = {
        meterReading: merge(applicationConfig?.meterReading || {}, jobConfig?.meterReading || {}, meterReadingOptions) as MeterReadingConfigType,
        features: merge(applicationConfig?.features || {}, jobConfig?.features || {}) as FeaturesConfigType,
    };

    return c as ApplicationConfig;
};

export default JobDetails;
