import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { jobsActions, jobsSelectors, jobsTypes } from './index';
import axios from 'axios';
import { loginSelectors, loginTypes } from '../login';
import { JobType, MeterRateTaskType } from './jobs.types';
import { normalize } from 'normalizr';
import { jobListSchema } from '../store.schema';
import { getToken } from '../login/login.saga';
import { notificationActions } from '../notification';
import { offlineActions, offlineSelectors } from '../offline';
import { MeterReadingTaskResultAction } from './jobs.actions';
import { NotificationType } from '../../screens/MainScreen/NotificationWrapper/NotificationWrapper';
import { communicationActions } from '../communication';

function* loadJobs() {
    const logins: any[] = yield select(loginSelectors.getLogins);

    if (logins.length === 0) {
        yield put(jobsActions.loadJobsSetLoading(false));
        return;
    }

    const isBlocked: boolean = yield select(jobsSelectors.isLoadingBlocked);
    if (isBlocked) {
        return;
    }

    yield put(jobsActions.loadJobsSetLoading(true));

    for (let [loginId] of logins) {
        try {
            const token: string = yield call(getToken, loginId);
            const { data } = yield axios.get('/api/pro/job', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const norm = normalize(
                data.jobs.map((job: JobType) => enrichJob(loginId, job)),
                jobListSchema,
            );

            /*
             we remove jobs, that are not part of the response (and belong to the same account id)
             */
            const newJobIds = norm.result;
            const existingJobs: JobType[] = yield select(jobsSelectors.getJobsByAccountId(loginId));

            const remove = existingJobs.map((j) => j.id).filter((id) => !newJobIds.includes(id));

            if (remove.length > 0) {
                yield put(jobsActions.removeJobsByIds(remove));
            }

            yield put(jobsActions.addEntities(norm));
        } catch (e) {
            const isOnline: boolean = yield select(offlineSelectors.isOnline);

            if (isOnline) {
                if (e.response?.status === 401) {
                    console.log('Account expired');
                } else {
                    yield put(notificationActions.showErrorNotification('Aktualisierung fehlgeschlagen', 'Aufträge konnten nicht abgerufen werden'));
                }
            } else {
                // yield put(notificationActions.showInfoNotification("Aufträge nicht aktuell", "Die Aufträge konnten nicht aktualisiert werden. Sie arbeiten mit offline Daten!"))
            }
        }
    }

    yield delay(1000);
    yield put(jobsActions.loadJobsSetLoading(false));
}

const enrichJob = (loginId: string, job?: JobType) => {
    if (!job) return undefined;

    return {
        ...job,
        login: loginId,
        sameTargetReadingDates: !!job.meter.meterRateTasks.reduce((res: any, mr: MeterRateTaskType) => {
            if (res === undefined) {
                return mr.targetReadingDate;
            }
            if (res === mr.targetReadingDate) return res;
            return false;
        }, undefined),
    };
};

function* sendTaskResults(action: MeterReadingTaskResultAction) {
    const job: JobType = yield select(jobsSelectors.getJobById(action.jobId));
    const token: string = yield getToken(job.login);

    try {
        const {
            data,
        }: {
            data: JobType;
        } = yield axios.post(
            `/api/pro/job/${job.id}/meterRateTask/result`,
            {
                results: action.taskResults,
                readingDate: action.readingDate,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        yield put(jobsActions.sendMeterTaskResultsDone(action.jobId, enrichJob(job.login, data)));
        yield put(notificationActions.scrollToTop());
        yield put(notificationActions.showNotification('Senden erfolgreich', 'Die Ergebnisse wurden erfolgreich gesendet.', NotificationType.SUCCESS));

        yield put(communicationActions.unblockSendCRMReadingReceiptRequest());
    } catch (err) {
        yield put(notificationActions.scrollToTop());
        const isOnline: boolean = yield select(offlineSelectors.isOnline);
        if (!isOnline || !err.status) {
            yield put(offlineActions.queueAction(action));
        } else {
            yield put(
                notificationActions.showErrorNotification(
                    'Senden fehlgeschlagen',
                    'Die Ergebnisse konnten nicht gesendet werden. Bitte versuche es später erneut.',
                ),
            );
        }
    }
}

function* removeJobsForAccount({ accountId }: { accountId: string; type: string }) {
    let jobIds: string[] = yield select(jobsSelectors.getJobIdsByAccountId(accountId));
    yield put(jobsActions.removeJobsByIds(jobIds));
}

function* fetchJobsAfterAccountAdded() {
    yield put(jobsActions.loadJobsSetLoading(false));
    yield put(jobsActions.loadJobs());
}

export default function* jobsSaga() {
    yield takeEvery(jobsTypes.LOAD_JOBS, loadJobs);
    yield takeLatest(loginTypes.ADD_ACCOUNT, fetchJobsAfterAccountAdded);
    yield takeEvery(jobsTypes.SEND_METER_TASK_RESULTS, sendTaskResults);

    yield takeEvery(loginTypes.REMOVE_ACCOUNT, removeJobsForAccount);

    // unblock after reload
    yield put(jobsActions.loadJobsSetLoading(false));

    let numberOfLogins: number = yield select(loginSelectors.getLoginCount);
    if (numberOfLogins > 0) {
        yield put(jobsActions.loadJobs());
    }
}
