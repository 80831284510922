import { call, delay, fork, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { loginActions, loginSelectors, loginTypes } from './index';
import { AccountInfoType, JWTTokenInfoType, LoginType, LoginWithTokenType, LoginWithUsernameType } from './login.types';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { applicationSelectors } from '../application';
import { push } from 'connected-react-router';
import { jobsActions } from '../jobs';
import { notificationActions } from '../notification';

function* refreshToken(loginId: string) {
    let loggedIn = true;
    while (loggedIn) {
        let login: LoginType = yield select(loginSelectors.getLoginById(loginId));
        if (!login || !login.tokenInfo) {
            yield take(loginTypes.LOGIN_JWT_TOKEN_RECEIVED);
            continue;
        }

        let expiresIn = login.tokenInfo.exp - Math.floor(new Date().getTime() / 1000);

        if (expiresIn > 0) {
            let { logout } = yield race({
                wait: delay((expiresIn - 60) * 1000),
                logout: take(loginTypes.LOGOUT),
            });
            if (logout) {
                return;
            }
        }
        try {
            yield call(loadToken, login.refreshToken, login.tokenInfo.sub);
        } catch (e) {
            loggedIn = false;
        }
    }
}

export function* getToken(accountId: string) {
    let login: LoginType = yield select(loginSelectors.getLoginById(accountId));

    while (!login || !login.tokenInfo) {
        yield race({
            jwt: take(loginTypes.LOGIN_JWT_TOKEN_RECEIVED),
            addAccount: take(loginTypes.ADD_ACCOUNT),
        });
        login = yield select(loginSelectors.getLoginById(accountId));
    }

    let expiresIn = login.tokenInfo.exp - Math.floor(new Date().getTime() / 1000);
    if (expiresIn < 30) {
        let { token } = yield call(loadToken, login.refreshToken, login.tokenInfo.sub);
        return token as string;
    }
    return login.token as string;
}

function* hasSelfParent(parentLogin: string, accountId: string, jwtToken: string) {
    let accountInfo: AccountInfoType = yield call(getAccountInfoForToken, jwtToken);

    if (accountInfo.parentId) {
        let existingLogins: Array<[string, LoginType]> = yield select(loginSelectors.getLogins);

        let parent = existingLogins.find(([_accountId, login]) => {
            return login.info?.id === accountInfo.parentId;
        });

        if (parent) {
            yield put(push(`/management/account/${parent[0]}/${accountInfo.id}`));
            return true;
        }
        return false;
    } else {
        return false;
    }
}

function* tokenLogin({ token }: LoginWithTokenType) {
    try {
        let {
            tokenInfo,
            token: jwtToken,
            accountId,
            refreshToken: refreshTokenData,
        }: {
            tokenInfo: JWTTokenInfoType;
            token: string;
            accountId: string;
            refreshToken: string;
        } = yield call(loadToken, token, undefined);

        let selfParent: boolean = yield call(hasSelfParent, tokenInfo.sub, tokenInfo['envago:ksaAccountId'], jwtToken);
        if (selfParent) {
            console.log('This Account is a child of its own :)');
        } else {
            yield put(loginActions.addAccount(accountId, jwtToken, refreshTokenData, tokenInfo));

            yield fork(refreshToken, tokenInfo['envago:ksaAccountId']);
        }
    } catch (e) {
        if (e.response) {
            console.log(e.response);
        } else {
            console.log(e);
        }
        yield put(loginActions.loginWithTokenError('Zugang ungültig oder abgelaufen'));
    }
}

function* usernameLogin({ username, password }: LoginWithUsernameType) {
    const applicationId: string = yield select(applicationSelectors.getApplicationId);
    try {
        let { data } = yield axios.post(
            `/auth/token`,
            {
                applicationId: applicationId,
                username: username?.replace(/\s/g, ''),
                password: password?.replace(/\s/g, ''),
            },
            {},
        );

        let tokenInfo: JWTTokenInfoType = jwtDecode(data.token);

        yield put(loginActions.addAccount(data.username, data.token, data.refreshToken, tokenInfo));

        return tokenInfo;
    } catch (e) {
        console.log('LoginError', e);
        yield put(loginActions.loginWrongCredentials());
    }
}

export function* loadToken(token: string, accountId: undefined | string) {
    const applicationId: string = yield select(applicationSelectors.getApplicationId);

    while (true) {
        try {
            let { data } = yield axios.post(
                `/auth/token`,
                {
                    applicationId: applicationId,
                    token: token,
                },
                {},
            );

            let tokenInfo: JWTTokenInfoType = jwtDecode(data.token);

            yield put(loginActions.loginJwtTokenReceived(data.username, data.token, data.refreshToken, tokenInfo));

            return {
                accountId: data.username,
                token: data.token,
                refreshToken: data.refreshToken,
                tokenInfo,
            };
        } catch (e) {
            if (e.response) {
                console.log('Response', e.response);
                if (accountId) {
                    yield put(loginActions.removeAccount(accountId));
                }
                throw e;
            }
            console.log(navigator.onLine, 'is online?');
            console.log('Error fetching token, will retry');
            yield delay(30000);
        }
    }
}

// function* setupTokenRefresh({ account }: { account: string }) {
//     yield fork(refreshToken, account);
// }

function* logout() {
    yield put(push('/'));
}

function* loadAccountInfo({ account }: { account: string; type: string }) {
    try {
        let token: string = yield call(getToken, account);

        let accountInfo: AccountInfoType = yield call(getAccountInfoForToken, token);

        yield put(loginActions.accountInformationReceived(account, accountInfo));
    } catch (e) {
        console.log(e);
    }
}

function* getAccountInfoForToken(token: string) {
    let { data: accountInfo }: { data: AccountInfoType } = yield axios.get(`/api/pro/account/info`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return accountInfo;
}

function* watchLoginProcess() {
    try {
        while (true) {
            yield race({
                tokenLogin: take(loginTypes.TOKEN_LOGIN),
                userPasswordLogin: take(loginTypes.USER_PASSWORD_LOGIN),
            });
            let { account } = yield race({
                account: take(loginTypes.ADD_ACCOUNT),
                error: take(loginTypes.LOGIN_WRONG_CREDENTIALS),
            });

            const isMultipleAccountLoginEnabled: boolean = yield select(applicationSelectors.isMultipleAccountsLoginEnabled);

            if (!isMultipleAccountLoginEnabled) {
                const existingLogins: Array<[string, LoginType]> = yield select(loginSelectors.getLogins);

                for (let [accountId] of existingLogins) {
                    if (accountId !== account.account) {
                        yield put(loginActions.removeAccount(accountId));
                    }
                }
            }

            if (account) {
                yield put(loginActions.fetchAccountInformation(account.account));
                yield put(jobsActions.loadJobs());

                const isRedirectToJobsScreenOnInitEnabled: boolean = yield select(applicationSelectors.isRedirectToJobsScreenOnLoginEnabled);

                if (isRedirectToJobsScreenOnInitEnabled) {
                    yield put(push('/ablesung'));
                } else {
                    yield put(push('/'));
                }
            }
        }
    } catch (err) {
        console.log('error in watchLoginProcess: ', err);
    }
}

function* checkLogins() {
    /*
    we delay the check-login a bit: if token-login is currently
     */
    yield delay(2000);

    let logins: LoginType[] = yield select(loginSelectors.getLogins);

    if (logins.length === 0) {
        console.log('All Logins expired');
        yield put(loginActions.logout());
    }
}

function* cleanRemovedAccounts() {
    let existingLogins: Array<[string, LoginType]> = yield select(loginSelectors.getLogins);
    for (let [accountId, login] of existingLogins) {
        if (login.refreshToken === undefined && login.token === undefined) {
            yield put(loginActions.removeAccount(accountId));
        }
    }
}

function* showAccountRemovedNotification() {
    yield put(notificationActions.showInfoNotification('Zugang abgelaufen', 'Ein abgelaufener Zugang wurde entfernt'));
}

export default function* loginSaga() {
    console.log('LoginSaga Ready!');

    const isMultipleAccountLoginEnabled: boolean = yield select(applicationSelectors.isMultipleAccountsLoginEnabled);

    yield call(cleanRemovedAccounts);

    // yield takeLatest(loginTypes.LOGIN_JWT_TOKEN_RECEIVED, setupTokenRefresh);
    yield takeLatest(loginTypes.TOKEN_LOGIN, tokenLogin);
    yield takeLatest(loginTypes.USER_PASSWORD_LOGIN, usernameLogin);
    yield takeLatest(loginTypes.LOGOUT, logout);
    yield takeEvery(loginTypes.FETCH_ACCOUNT_INFO, loadAccountInfo);

    yield fork(watchLoginProcess);

    yield takeLatest(loginTypes.REMOVE_ACCOUNT, checkLogins);

    if (isMultipleAccountLoginEnabled) {
        yield takeLatest(loginTypes.REMOVE_ACCOUNT, showAccountRemovedNotification);
    }

    let loginIds: string[] = yield select(loginSelectors.getLoginIds);
    for (let loginId of loginIds) {
        console.log('Setup for Login', loginId);
        yield fork(refreshToken, loginId);
        yield put(loginActions.fetchAccountInformation(loginId));
    }

    yield put(loginActions.loginInitializationDone());
}
