import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './Collapsable.less';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

type Props = {
    triggerText: string | ReactNode;
    arrowPosition?: 'left' | 'right';
    children: ReactNode;
    className?: string;
    onChange?: (visible: boolean) => void;
    defaultOpen?: boolean;
};

const Collapsable = ({
    triggerText,
    children,
    className,
    onChange,
    defaultOpen = false,
    arrowPosition = 'left',
}: Props) => {
    const [open, setOpen] = useState(defaultOpen);

    const contentRef = useRef<HTMLDivElement>(null);

    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (contentRef.current) {
            setHeight(contentRef.current.scrollHeight);

            let images = contentRef.current.getElementsByTagName('img');
            if (images) {
                Array.prototype.slice.call(images).forEach((img) => {
                    if (!(img.complete && img.naturalHeight !== 0)) {
                        img.onload = () => {
                            if (contentRef.current) setHeight(contentRef.current.scrollHeight);
                        };
                    }
                });
            }
        }
    }, [contentRef.current]);

    useEffect(() => {
        if (onChange) onChange(open);
    }, [open]);

    return (
        <div className={'collapsable'}>
            <div
                className={classNames(
                    'collapsable-trigger cursor-pointer flex items-center -ml-1',
                    {
                        'justify-between flex-row-reverse': arrowPosition === 'right',
                    },
                    className,
                )}
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <Icon
                    path={Icon.Path.mdiChevronRight}
                    className={classNames('w-5 transform transition flex-shrink-0', {
                        'rotate-90': open,
                    })}
                />
                <div className={'flex-grow'}>{triggerText}</div>
            </div>

            <div
                ref={contentRef}
                className={classNames(
                    'collapsable-content transition-all overflow-hidden origin-top',
                    {
                        'collapsable-content-open': open,
                    },
                )}
                style={{ maxHeight: !open ? 0 : height }}
            >
                {children}
            </div>
        </div>
    );
};

export default Collapsable;
