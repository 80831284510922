import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './i18n';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//     onUpdate: (registration) => {
//         console.log('registration updated?');
//         const waitingServiceWorker = registration.waiting;
//
//         if (waitingServiceWorker) {
//             console.log('Service Worker is waiting for Update!');
//             waitingServiceWorker.addEventListener('statechange', (event) => {
//                 if (event && event.target && 'state' in event.target) {
//                     if (event.target?.state === 'activated') {
//                         window.location.reload();
//                     }
//                 }
//             });
//             waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//         }
//     },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
