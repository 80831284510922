import Card from '../../../components/envago/Card/Card';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { communicationSelectors } from '../../../redux/communication';
import { useTranslation } from 'react-i18next';
import CommunicationTrigger from './CommunicationTrigger';

interface CommunicationHomeSectionProps {}

const CommunicationHomeSection = ({}: CommunicationHomeSectionProps) => {
    const { t } = useTranslation();
    const isRegistered = useSelector(communicationSelectors.isRegisteredForUserReadingReceiptViaCRM);

    const [isVisible, setIsVisible] = useState(false);

    const texts: { description: string; bulletPointsTitle: string; bulletPoints: Array<string> } = useMemo(() => {
        return {
            description: isRegistered ? t('communication.home.communication.description') : t('communication.home.registration.description'),
            bulletPointsTitle: isRegistered
                ? t('communication.home.registration.descriptionBulletPointsTitle')
                : t('communication.home.registration.descriptionBulletPointsTitle'),
            bulletPoints: isRegistered
                ? t('communication.home.registration.descriptionBulletPoints', { returnObjects: true })
                : t('communication.home.registration.descriptionBulletPoints', { returnObjects: true }),
        };
    }, [isRegistered, t]);

    return (
        <Card className={`md:col-start-2 ${isVisible ? '' : 'hidden'}`}>
            <h2>{t('communication.home.title')}</h2>
            <p>
                <div className={'mb-3'}>
                    <p>{texts.description}</p>
                </div>
                <div>
                    <p className={'font-bold mb-1'}>{texts.bulletPointsTitle}</p>
                    <div className={'pl-2 pb-4'}>
                        <ul className={'list-disc list-inside'}>
                            {texts.bulletPoints.map((p, index) => (
                                <li key={index} className={'leading-6'}>
                                    {p}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </p>
            <CommunicationTrigger
                triggerButtonProps={{
                    block: true,
                    children: isRegistered ? t('communication.triggerButton.communication') : t('communication.triggerButton.registration'),
                }}
                placement={'HOME'}
                onVisibilityChange={(isVisible) => setIsVisible(isVisible)}
            />
        </Card>
    );
};

export default CommunicationHomeSection;
